/** @format */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.banner_section {
  background-image: url(../Images/bannerdog.png);
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
}
