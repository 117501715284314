/** @format */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signup_bg {
  background-image: url(./Images/pets.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  /* height: 100vh; */
  padding-top: 30px;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiSelect-icon.MuiSelect-iconOutlined.css-1u6jos5-MuiSvgIcon-root-MuiSelect-icon {
  color: "white" !important;
}
.about_banner {
  background-image: url(./Images/aboutbanner.png);
  background-size: cover;
  background-repeat: no-repeat;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ****** chat design ********* */
.chat-container {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.chat-messages {
  height: 300px;
  overflow-y: auto;
  background-color: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.chat-message {
  padding: 8px;
  background-color: #e1e1e1;
  border-radius: 5px;
  margin-bottom: 5px;
}

.chat-form {
  display: flex;
}

.chat-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-send-btn {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.chat-box {
  height: 400px;
  overflow-y: scroll;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}
@media screen and (max-width: 480px) {
  .blog_cards {
    margin-top: 20px;
  }
  .about_banner {
    height: 65vh;
  }
}

/* ***** header css ******** */
.header-navbar {
  background-color: #482f30;
}

.nav-link {
  color: white !important;
  padding: 20px;
}

.header-toggle {
  background-color: white;
}

.header-icons {
  display: flex;
  align-items: center;
}

.header-user-icon {
  width: 30px;
  height: 30px;
}

.profile-link {
  text-decoration: none;
  color: white;
  padding-left: 20px;
}

.chat-link {
  margin-left: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .nav-link {
    padding: 10px;
  }

  .header-user-icon {
    width: 25px;
    height: 25px;
  }

  .profile-link {
    padding-left: 10px;
  }
}
